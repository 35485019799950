import React, { ReactElement, useEffect, useState } from 'react';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import { useResourcePlannerDispatch, useResourcePlannerState } from '../../context/resource-planner-context';
import { Actions } from '../../reducers/resource-planner-reducer';
import styles from './user-setting-modal.module.scss';
import { PulseTabsProps } from 'components/pulse-tabs/pulse-tabs-types';
import PulseTabs from 'components/pulse-tabs/pulse-tabs';
import { PulseTabsTheme } from 'pulse-commons/types';
import ResourceBookingsTab, { convertData, getDefaultValue } from './components/resource-booking';
import AssignedGroupsTab from './components/assigned-groups';
import clsx from 'clsx';
import { PULSE_LOCAL_URL } from 'pulse-commons/helpers';
import { v2Endpoint } from 'pulse-api/base';
import { Group } from './user-setting-modal-types';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import { Colors } from 'pulse-commons/types';
import qs from 'qs';
import { SchedulerPro } from '@bryntum/schedulerpro-thin';
import isEqual from 'lodash/isEqual';

export const groupsUrl = `${PULSE_LOCAL_URL}/api.v2.php`;
export const params = {
  action: 'social',
  itemType: null,
  limit: 200,
  paginate: 'y',
  socialType: 'rooms',
  type: 'getGroups',
  filter: {
    type: ['status', 'invited-projects', 'projectType'],
    value: 'all',
  },
};
const queryParamsString = qs.stringify(params, { encode: false });

export const getPlannerPermission = (
  scheduler_access_edit: 'n' | 'y' = 'n',
  scheduler_access_view: 'n' | 'y' = 'n',
): string => {
  if (scheduler_access_edit === 'y' && scheduler_access_view === 'y') {
    return 'edit';
  }

  if (scheduler_access_edit === 'n' && scheduler_access_view === 'n') {
    return 'n';
  }

  if (scheduler_access_edit === 'n' && scheduler_access_view === 'y') {
    return 'view';
  }

  return 'n';
};

const getModifiedObjects = (compareRef = false, userInfo = {}, originalUserInfo = {}) => {
  return Object.keys(userInfo).reduce((result, key) => {
    if (!originalUserInfo.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(userInfo[key], originalUserInfo[key])) {
      const resultKeyIndex = result.indexOf(key);

      if (compareRef && userInfo[key] !== originalUserInfo[key]) {
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(originalUserInfo));
};

const UserForm = ({
  removeResource,
  schedulerInstance,
}: {
  removeResource?: (resourceId: number) => void;
  schedulerInstance: SchedulerPro;
}): ReactElement => {
  const rpState = useResourcePlannerState();
  const rpDispatch = useResourcePlannerDispatch();
  const { isEditUserFormVisible, editUserIdForm } = rpState;

  const [userInfo, setUserInfo] = useState<any>();
  const [originalUserInfo, setOriginalUserInfo] = useState<any>();
  const [availableGroups, setAvailableGroups] = useState<Group[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group[]>([]);

  const maxHours = Number(userInfo?.max_hours ?? 0);
  const groupData = selectedGroup.map(group => ({
    type: 'groups',
    id: group.id,
  }));

  const handleCloseModal = () => {
    rpDispatch({
      type: Actions.hideUserFormVisible,
      payload: {
        isEditUserFormVisible: false,
      },
    });
  };

  const onSavingSettingForm = async () => {
    setUserInfo({ ...userInfo, mapped_groups: selectedGroup });

    rpDispatch({
      type: Actions.setLoadingTrue,
    });

    const differedKeys = getModifiedObjects(false, userInfo, originalUserInfo);
    const data = {};

    differedKeys.forEach(key => {
      if (key != 'scheduler_access_edit' && key != 'department') {
        data[key] = userInfo[key];
      }
    });

    data['scheduler_access'] = getPlannerPermission(userInfo.scheduler_access_edit, userInfo.scheduler_access_view);
    data['max_hours'] = maxHours;

    await v2Endpoint
      .request({
        method: 'PATCH',
        url: `${PULSE_LOCAL_URL}/v2/api/users/${editUserIdForm}`,
        data: {
          data: {
            type: 'users',
            id: editUserIdForm,
            attributes: data,
            ...(selectedGroup.length && { relationships: { groups: { data: groupData } } }),
          },
        },
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      })
      .then(async () => {
        handleCloseModal();
        window.utilities?.notification?.success('User Updated');
        const updatedUser = schedulerInstance.resourceStore.getById(editUserIdForm);
        if (updatedUser) {
          updatedUser.set({
            departments: {
              department_id: userInfo.department_id,
              name: userInfo.department?.label,
            },
            roles: {
              role_id: userInfo.role_id,
              name: userInfo.role?.label,
            },
          });
        }
        rpDispatch({
          type: Actions.setLoadingFalse,
        });
      })
      .catch(() => {
        window.utilities?.notification?.warning('Update Failed');
        rpDispatch({
          type: Actions.setLoadingFalse,
        });
      });
    if (userInfo.bookable === 'n' && removeResource) {
      removeResource(editUserIdForm);
    }
  };

  const handleChangeBookable = event => {
    const value = event.target.value;
    setUserInfo({ ...userInfo, bookable: value });
  };

  const handleChangePermission = event => {
    const value = event.target.value;
    switch (value) {
      case 'n':
        setUserInfo({ ...userInfo, scheduler_access_edit: 'n', scheduler_access_view: 'n' });
        break;
      case 'view':
        setUserInfo({ ...userInfo, scheduler_access_edit: 'n', scheduler_access_view: 'y' });
        break;
      case 'edit':
        setUserInfo({ ...userInfo, scheduler_access_edit: 'y', scheduler_access_view: 'y' });
        break;
      default:
        break;
    }
  };

  const handleChangeDepartment = record => {
    setUserInfo({
      ...userInfo,
      department: record ?? null,
      department_id: record ? Number(record.value) : null,
    });
  };

  const handleChangeActivity = record => {
    setUserInfo({ ...userInfo, default_timesheet_activity_id: record ? Number(record.value) : null });
  };

  const handleChangeRole = record => {
    setUserInfo({
      ...userInfo,
      role: record ?? null,
      role_id: record ? Number(record.value) : null,
    });
  };

  const handleChangeBookableHours = event => {
    setUserInfo({ ...userInfo, max_hours: event.target.value });
  };

  const handleAddGroup = (group: Group) => {
    const removeGroupFromAvailable = availableGroups.filter(each => each.id !== group.id);
    setAvailableGroups(removeGroupFromAvailable);
    setSelectedGroup([...selectedGroup, group]);
  };

  const handleRemoveGroup = (group: Group) => {
    const removeGroupFromSelected = selectedGroup?.filter(each => each.id !== group.id);
    setSelectedGroup(removeGroupFromSelected);
    setAvailableGroups([...availableGroups, group]);
  };

  const settingTabs: PulseTabsProps['tabData'] = [
    {
      id: 'resource_bookings',
      tabLabel: 'RESOURCE BOOKINGS',
      TabProps: {
        classes: {
          selected: styles['tabs__header--selected'],
        },
      },
      tabContent: userInfo && (
        <ResourceBookingsTab
          userData={userInfo}
          handleChangeBookable={handleChangeBookable}
          handleChangeDepartment={handleChangeDepartment}
          handleChangeActivity={handleChangeActivity}
          handleChangeRole={handleChangeRole}
          handleChangeBookableHours={handleChangeBookableHours}
          handleChangePermission={handleChangePermission}
        />
      ),
      TabPanelProps: {
        classes: {
          root: styles['resource-booking__body'],
        },
      },
    },
    {
      id: 'assigned_groups',
      tabLabel: 'ASSGINED GROUPS',
      TabProps: {
        classes: {
          selected: styles['tabs__header--selected'],
        },
      },
      tabContent: userInfo && (
        <AssignedGroupsTab
          availableGroups={availableGroups}
          selectedGroup={selectedGroup}
          handleAddGroup={handleAddGroup}
          handleRemoveGroup={handleRemoveGroup}
        />
      ),
      TabPanelProps: {
        classes: {
          root: styles['assigned-groups__body'],
        },
      },
    },
  ];

  const fetchUser = async () => {
    const userInfoUrl = `${PULSE_LOCAL_URL}/v2/ajax/address-book/users/view/${editUserIdForm}`;
    let currentGroups;
    rpDispatch({
      type: Actions.setLoadingTrue,
    });
    await v2Endpoint
      .get(userInfoUrl)
      .then(res => {
        const defaultDeaprtmentValue = getDefaultValue(
          res.data.user.department_id,
          convertData(res.data.user.departments),
        );
        const defaultRoleValue = getDefaultValue(res.data.user.role_id, convertData(res.data.user.roles));
        setOriginalUserInfo({
          ...res.data.user,
          department: defaultDeaprtmentValue ? defaultDeaprtmentValue[0] : null,
          role: defaultRoleValue ? defaultRoleValue[0] : null,
        });
        setUserInfo({
          ...res.data.user,
          department: defaultDeaprtmentValue ? defaultDeaprtmentValue[0] : null,
          role: defaultRoleValue ? defaultRoleValue[0] : null,
        });
        setSelectedGroup(res.data.user.mapped_groups);
        currentGroups = res.data.user.mapped_groups;
        rpDispatch({
          type: Actions.setLoadingFalse,
        });
        return v2Endpoint(`${groupsUrl}?${queryParamsString}`);
      })
      .then(res => {
        const filterAvailableGroup = res.data.data;
        const newArr = filterAvailableGroup.filter(each => {
          return !currentGroups.find(selected => selected.id === each.id);
        });
        setAvailableGroups(newArr);
      })
      .catch(error => {
        window.utilities && window.utilities?.notification.danger(error);
      });
  };

  useEffect(() => {
    if (!isEditUserFormVisible) return;
    fetchUser();
  }, [isEditUserFormVisible]);

  return (
    <PulseModalBase
      open={isEditUserFormVisible}
      headerPrimaryText={`User settings: ${userInfo?.name}`}
      onModalClose={handleCloseModal}
      classes={{
        root: [styles['user-setting__body']],
        dialog: {
          paper: styles['user-setting__root'],
        },
      }}
      PulseModalHeaderProps={{
        classes: {
          root: styles['user-setting__header__root'],
          titleContainer: styles['user-setting__header__ctn'],
          titlePrimary: styles['user-setting__header__title'],
          closeBtn: styles['user-setting__header__closeBtn'],
        },
        headerIconClasses: clsx(styles['user-setting__header__icon'], 'fal fa-user'),
      }}
      footer={true}
      footerComponent={
        <div className={styles['user-setting__footer']}>
          <PulseButtonBase label={'Cancel'} icon={false} onClick={handleCloseModal} />
          <PulseButtonBase
            label={'Update User'}
            icon={true}
            iconClasses={{ icon: 'fal fa-save' }}
            onClick={onSavingSettingForm}
            color={Colors.success}
          />
        </div>
      }
    >
      <PulseTabs
        classes={{
          root: styles['user-setting__tabs'],
        }}
        TabsProps={{
          classes: {
            root: styles['tabs__header'],
          },
        }}
        tabData={settingTabs}
        theme={PulseTabsTheme.light}
      />
    </PulseModalBase>
  );
};

export default UserForm;
