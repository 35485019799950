import React, { useState, FC, useEffect } from 'react';
import styles from './timeline-predecessor-warning-modal.module.scss';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import { TimelinePredecessorWarningModalProps, OptionType } from './timeline-predecessor-warning-modal-types';

const TimelinePredecessorWarningModal: FC<TimelinePredecessorWarningModalProps> = props => {
  const { isOpen, handleCloseModel, handleProceed } = props;

  return (
    <PulseModalBase
      variant="jqconfirm"
      open={isOpen}
      headerIconClasses={'fa-regular fa-circle-exclamation'}
      footer={true}
      PulseModalHeaderProps={{
        onCloseClick: handleCloseModel,
        primaryText: 'Date Change: Predecessor Relationships',
        classes: {
          root: 'export-timeline-modal__root',
          closeBtn: 'export-timeline-modal__close-btn',
        },
      }}
      onModalClose={handleCloseModel}
      onConfirmClick={handleProceed}
      PulseModalFooterProps={{
        // classes: {
        //   root: [styles['non-working-days-modal__footer']],
        // },
        confirmText: 'proceed',
        onConfirmClick: handleProceed,
      }}
    >
      <div className={styles['modal--content']}>
        <div className={styles['modal--content__timeline--title']}>
          <span>
            Manually changing the start date will result in the removal of the predecessor relation for this item.
          </span>
          <span>Do you wish to continue?</span>
        </div>
      </div>
    </PulseModalBase>
  );
};

export default TimelinePredecessorWarningModal;
