import React, { ReactElement } from 'react';
import { PulseIconProps } from '../../types/icon-types';
import './pulse-icons.scss';
import '@fortawesome/fontawesome-pro/scss/light.scss';
import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
import '@fortawesome/fontawesome-pro/scss/brands.scss';
import '@fortawesome/fontawesome-pro/scss/regular.scss';
import { IconSizes } from 'pulse-commons/types';
import clsx from 'clsx';
import deepEqual from 'lodash/isEqual';

const isEqual = (prevProps: PulseIconProps, nextProps: PulseIconProps) => {
  return prevProps.iconName === nextProps.iconName && deepEqual(prevProps.classes, nextProps.classes);
};

const PulseIcon = (props: PulseIconProps): ReactElement => {
  const { classes, size = IconSizes.md, iconName } = props;

  const iconClassNameArray: string = clsx('pulse-icon', classes?.icon, size);
  const rootClassNameArray: string = clsx('pulse-icon__ctn', classes?.root);

  return (
    <span data-testid="pulse-icon" className={rootClassNameArray}>
      <i className={iconClassNameArray}>{iconName}</i>
    </span>
  );
};

export default React.memo<PulseIconProps>(PulseIcon, isEqual);
